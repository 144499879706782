<template>
  <div class="page">
    <section>
      <span class="title">专家委员</span>

      <!-- 广告轮播图 -->
      <advBanner
        :bannerArr="bannerArr"
        carouselWidth="1200px"
        carouselHeight="470px"
      ></advBanner>
      <!-- 广告轮播图 -->

      <div class="department">
        <div class="firstLevel">一级：</div>
        <div class="allDepartment">
          <span
            class="departmentName"
            v-for="(item, index) in firstLevelList"
            :key="index"
            :class="index == tabIndex ? 'active' : ''"
            @click="changeTabIndex(index, item)"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <div class="department" v-loading="secondLoading">
        <span class="firstLevel">二级：</span>
        <div class="allDepartment">
          <span
            class="departmentName"
            v-for="(item, index) in secondLevelList"
            :key="index"
            :class="index == tabIndex1 ? 'active' : ''"
            @click="changeTabIndex1(index, item)"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <template v-if="doctorList.length">
        <div
          v-loading="listLoading"
          v-if="listLoading"
          class="group min400"
        ></div>
        <div class="group" v-else>
          <div
            class="information"
            @click="
              $router.push({
                path: '/index-expertCommittee-doctorDetails',
                query: { id: item.id },
              })
            "
            v-for="(item, index) in doctorList"
            :key="index"
          >
            <el-image
              class="groupBanner"
              :src="item.images"
              fit="cover"
            ></el-image>
            <div class="information-title">
              <span class="name">{{ item.name }}</span>
              <span class="position">{{ item.position }}</span>
            </div>
            <div class="informationDepartment">
              <span class="physician">{{ item.station }}</span>
              <!-- <span>{{ title }}</span> -->
              <span>{{ item.department }}</span>
            </div>
            <span>{{ item.hospital }}</span>
          </div>
        </div>
        <div class="foot-page" v-if="total > 12">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-size="12"
            layout="prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </template>
      <div v-else class="boxNull">
        <div class="null-box">
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
          />
          <div class="title">暂无数据</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import advBanner from "@components/common/advBanner";

export default {
  components: {
    advBanner,
  },
  data() {
    return {
      bannerArr: [],
      swiperList: [],
      tabIndex: 0,
      firstLevelList: [],
      twoId: 0,
      secondLevelList: [],
      tabIndex1: 0,
      doctorId: 0,
      page: 1,
      total: 0,
      doctorList: [],
      secondLoading: false,
      listLoading: false,
      title: "",
    };
  },
  async mounted() {
    this.xin.isLoadding(true);
    this.getBanner();
    await this.getExpert_typeList();
    await this.getTwo_expert_typeList(this.twoId);
    await this.getExpertList(this.doctorId);
    this.xin.isLoadding(false);
    this.getBannerXin();
  },
  methods: {
    async getBannerXin() {
      let res = await this.$findApi.banner({
        position: 6,
      });
      if (res.code == 200) {
        this.bannerArr = res.data;
      }
    },

    async handleSizeChange(val) {
      this.page = val;
      this.listLoading = true;
      await this.getExpertList(this.doctorId);
      this.listLoading = false;
    },
    async handleCurrentChange(val) {
      this.page = val;
      this.listLoading = true;
      await this.getExpertList(this.doctorId);
      this.listLoading = false;
    },
    async changeTabIndex(index, item) {
      this.page = 1;
      this.tabIndex = index;
      this.tabIndex1 = 0;
      this.secondLoading = true;
      this.secondLevelList = [];
      this.listLoading = true;
      await this.getTwo_expert_typeList(item.id);
      await this.getExpertList(this.doctorId);
      this.secondLoading = false;
      this.listLoading = false;
    },
    async changeTabIndex1(index, item) {
      this.title = item.name;
      this.page = 1;
      this.tabIndex1 = index;
      this.listLoading = true;
      await this.getExpertList(item.id);
      this.listLoading = false;
    },
    async getExpertList(id) {
      let res = await this.$newsApi.getExpertList({
        type_id: id,
        page: this.page,
      });
      if (res.code == 200) {
        this.doctorList = res.data.data;
        this.total = res.data.total;
      }
    },
    async getBanner() {
      // this.xin.message('内容')
      let res = await this.$newsApi.getBanner({ cate_id: 1 });
      if (res.code == 200) {
        this.swiperList = res.data;
      }
    },
    async getExpert_typeList() {
      let res = await this.$newsApi.getExpert_typeList();
      if (res.code == 200) {
        this.firstLevelList = res.data;
        this.twoId = this.firstLevelList[0].id;
      }
    },
    // 新闻和广告的路径跳转
    jumpTo(url, id, type) {
      if (type != 3) {
        this.$router.push({
          path: "/index-asdvertising",
          query: {
            is_adv: id,
          },
        });
      } else {
        window.open(url, "_blank");
      }
    },
    async getTwo_expert_typeList(id) {
      let res = await this.$newsApi.getTwo_expert_typeList({ p_id: id });
      if (res.code == 200) {
        this.secondLevelList = res.data;
        this.doctorId = this.secondLevelList[0].id;
        this.title = this.secondLevelList[0].name;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.page {
  .foot-page {
    margin-top: 60px;
    height: 40px;
    text-align: center;
    width: 100%;
  }
  background: #f2f2f2;
  > section {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .el-carousel {
      border-radius: 20px;
      margin-bottom: 30px;
    }
    > .title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-bottom: 23px;
    }
    > img {
      width: 1200px;
      height: 300px;
      object-fit: cover;
      border-radius: 20px;
      margin-bottom: 40px;
    }
    > .department {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px 0;
      box-sizing: border-box;
      background: #ffffff;
      color: #333333;
      font-size: 18px;
      width: 1200px;
      border-bottom: 1px solid #f2f2f2;
      &:last-child {
        border: 0;
      }
      > .firstLevel {
        color: #8f8f8f;
        margin-left: 20px;
        width: 70px;
      }
      > .allDepartment {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        > .departmentName {
          cursor: pointer;
          height: 35px;
          background: #fff;
          padding: 0 10px;
          border-radius: 3px;
          font-size: 18px;
          color: #333;
          line-height: 35px;
          text-align: center;
          &.active {
            color: #ffffff;
            background: $--themeColor;
          }
        }
      }
    }
    > .group {
      display: flex;
      flex-direction: row;
      margin-top: 30px;
      flex-wrap: wrap;
      // justify-content: space-between;
      > .information {
        cursor: pointer;
        color: #333333;
        margin-top: 40px;
        margin-right: 20px;
        &:nth-child(4n) {
          margin-right: 0;
        }
        > .groupBanner {
          width: 285px;
          height: 200px;
          object-fit: cover;
          border-radius: 10px;
          font-size: 16px;
        }
        > .information-title {
          display: flex;
          flex-direction: row;
          margin-top: 15px;
          > .name {
            font-size: 24px;
          }
          > .position {
            width: 80px;
            height: 25px;
            color: $--themeColor;
            border: 1px solid $--themeColor;
            border-radius: 6px;
            font-size: 12px;
            text-align: center;
            line-height: 25px;
            margin-left: 20px;
          }
        }
        > .informationDepartment {
          display: flex;
          flex-direction: row;
          margin: 14px 0;
          > .physician {
            margin-right: 18px;
          }
        }
      }
    }
  }
}
.boxNull {
  margin-top: 60px;
  text-align: center;
}
</style>
